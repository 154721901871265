export class Position {

	public x: number;
	public y: number;

	public constructor(x: number = 0, y: number = 0) {
		this.set(x, y);
	}

	public set(x?: number, y?: number): this {
		if(x !== undefined) {
			this.x = x;
		}

		if(y !== undefined) {
			this.y = y;
		}

		return this;
	}

	public add(x: number | Position, y: number = 0): this {
		if(x instanceof Position) {
			this.add(x.x, x.y);
		} else {
			this.x += x;
			this.y += y;
		}

		return this;
	}

	public sub(x: number | Position, y: number = 0): this {
		if(x instanceof Position) {
			this.sub(x.x, x.y);
		} else {
			this.x -= x;
			this.y -= y;
		}

		return this;
	}

	public invert(): this {
		this.x *= -1;
		this.y *= -1;

		return this;
	}

	public get squaredLength(): number {
		return this.x ** 2 + this.y ** 2;
	}

	public get length(): number {
		return Math.sqrt(this.squaredLength);
	}

	public copy(pos: Position): this {
		this.x = pos.x;
		this.y = pos.y;

		return this;
	}

	public clone(): Position {
		return new Position().copy(this);
	}

	public distance(pos: Position): number {
		return Math.sqrt((this.x - pos.x) ** 2 + (this.y - pos.y) ** 2);
	}

	public middle(pos: Position, dst: Position = new Position()): Position {
		return dst.set((this.x + pos.x) / 2, (this.y + pos.y) / 2);
	}

	public angle(pos: Position): number {
		return Math.atan2(pos.y - this.y, pos.x - this.x);
	}

	public equals(pos: Position): boolean {
		return this.x === pos.x && this.y === pos.y;
	}

	public zero(): this {
		return this.set(0, 0);
	}

}
