import Polygon from "polygon";
import { Builder } from "../builder";
import { IFacility } from "../controller";
import { overlap } from "../utils/overlap";
import { Position } from "../utils/position";
import { IRenderable } from "../utils/renderable";
import { m2cm, m2ft, m2inch } from "../utils/units";

const POLYGON_PADDING = 0.001;

function drawPath(ctx: CanvasRenderingContext2D, x: number, y: number, w: number, h: number, r: number) {
	ctx.beginPath();
	ctx.moveTo(x + r, y);
	ctx.arcTo(x + w, y, x + w, y + h, r);
	ctx.arcTo(x + w, y + h, x, y + h, r);
	ctx.arcTo(x, y + h, x, y, r);
	ctx.arcTo(x, y, x + w, y, r);
	ctx.closePath();
}

function expandPoints(center: Position, points: Array<[number, number]>, amount: number): Array<[number, number]> {
	const newPoints: Array<[number, number]> = [];

	for(const point of points) {
		const angle = Math.atan2(point[1] - center.y, point[0] - center.x);
		const radius = Math.sqrt((center.x - point[0]) ** 2 + (center.y - point[1]) ** 2) + amount;

		const x = radius * Math.cos(angle) + center.x;
		const y = radius * Math.sin(angle) + center.y;

		newPoints.push([x, y]);
	}

	return newPoints;
}

export class Path implements IRenderable {

	public locked: boolean;
	public id: number;
	public hidden: boolean;
	public angle: number;
	public offset: Position;
	public points: Position[];
	public hollow: boolean;
	public highlight: boolean;
	public error: boolean;
	public area: number;
	public center: Position;

	public type: "floor" | "area";
	public facilities: Set<number>;
	public price: number;
	public fprice: string;
	public currency: string;
	public name: string;
	public description: string;

	public constructor(points: Position[] = [], hollow: boolean = false, highlight: boolean = false) {
		this.hidden = false;
		this.locked = false;
		this.angle = 0;
		this.offset = new Position();
		this.points = points;
		this.hollow = !!hollow;
		this.highlight = !!highlight;
		this.error = false;
		this.name = null;
		this.id = null;

		this.update();
	}

	public render(builder: Builder): void {
		const points = this.points;
		if(this.hidden || points.length < 2) {
			return;
		}

		const ctx = builder.ctx;
		ctx.save();
		ctx.lineJoin = "round";
		ctx.lineWidth = 4 / builder.grid.zoom;

		if(this.locked) {
			ctx.fillStyle = "rgba(29, 170, 226, 0.15)";
			//ctx.fillStyle = "rgba(255, 255, 255, 0.5)";
			//ctx.strokeStyle = "#1daae2";
			ctx.strokeStyle = "rgba(29, 170, 226, 0.15)";
		} else if(!!this.highlight && !(!!this.error && builder.target === this)) {
			ctx.fillStyle = "rgba(29, 170, 226, 0.75)";
			//ctx.fillStyle = "rgba(29, 170, 226, 0.05)";
			ctx.strokeStyle = "#1daae2";
		} else if(!!this.error) {
			ctx.fillStyle = "rgba(255, 0, 0, 0.05)";
			ctx.strokeStyle = "#ff0000";
		} else {
			ctx.fillStyle = "rgba(0, 0, 0,  0.075)";
			ctx.strokeStyle = "#999999";
		}

		ctx.translate(
			this.center.x,
			this.center.y,
		);

		if(this.angle !== 0) {
			ctx.rotate(this.angle);
		}

		ctx.beginPath();

		let firstPoint = null;

		for(const point of points) {
			const pointX = point.x + this.offset.x - this.center.x;
			const pointY = point.y + this.offset.y - this.center.y;

			if(firstPoint) {
				ctx.lineTo(pointX, pointY);
			} else {
				ctx.moveTo(pointX, pointY);
				firstPoint = point;
			}
		}

		if(!this.hollow) {
			ctx.closePath();
			ctx.fill();
		}

		ctx.stroke();
		ctx.restore();

		if(!this.hollow) {
			builder.grid.undoTransform();

			ctx.textAlign = "center";
			ctx.textBaseline = "middle";
			ctx.font = "bold 16px FiraGO";

			const textX = (this.center.x + this.offset.x - builder.grid.x) * builder.grid.zoom;
			const textY = (this.center.y + this.offset.y - builder.grid.y) * builder.grid.zoom;

			let areaText = (function(area: number): string {
				if(builder.unit === "m") {
					if(area < 1) {
						return Math.floor(area * 100) + "cm";
					} else {
						return Math.floor(area) + "m";
					}
				} else if(builder.unit === "ft") {
					return Math.floor(m2ft(area, true)) + "ft";
				} else if(builder.unit === "in") {
					return Math.floor(m2inch(area, true)) + "in";
				} else if(builder.unit === "cm") {
					return Math.floor(m2cm(area, true)) + "cm";
				}
			})(this.area) + "²";

			if(this.name) {
				areaText = `${this.name}: ${areaText}`;
			}

			const textSize = ctx.measureText(areaText);
			const textWidth = textSize.width;

			ctx.fillStyle = "#ffffff";
			//ctx.fillStyle = "#228B22";
			

			ctx.shadowOffsetX = 1;
			ctx.shadowOffsetY = 3;
			ctx.shadowBlur = 5;
			ctx.shadowColor = "rgba(0, 0, 0, 0.1)";

			drawPath(ctx, textX - textWidth / 2 - 8, textY - (16 * 1.2 / 2) - 4, textWidth + 16, (16 * 1.2) + 8, 3);
			ctx.fill();

			ctx.fillStyle = "#000000";
			ctx.fillText(areaText, textX, textY);
			ctx.restore();
		}
	}

	public update(): void {
		const polygon = new Polygon(this.points);
		const center = polygon.center();

		if(!this.type) {
			this.area = Math.abs(polygon.area());
			this.area = Math.round(this.area * 1000) / 1000;
		}

		this.center = new Position(center.x + this.offset.x, center.y + this.offset.y);
	}

	public order(): number {
		return this.locked ? -100 : (this.highlight !== null ? 100 : 0);
	}

	public applyOffset(): void {
		for(const point of this.points) {
			point.x += this.offset.x;
			point.y += this.offset.y;
		}

		this.offset.set(0, 0);
		this.update();
	}

	public applyAngle(): void {
		const center = this.center;
		const sin = Math.sin(this.angle);
		const cos = Math.cos(this.angle);

		for(const point of this.points) {
			const px = point.x - center.x;
			const py = point.y - center.y;

			point.x = px * cos - py * sin + center.x;
			point.y = px * sin + py * cos + center.y;
		}

		this.angle = 0;
	}

	public overlaps(path: Path | Path[], applyFix: boolean = false): boolean {
		if(Array.isArray(path)) {
			for(const arrPath of path) {
				if(arrPath === this) {
					continue;
				}

				if(this.overlaps(arrPath, applyFix)) {
					return true;
				}
			}

			return false;
		}

		let p0 = this.points.map((point: Position): [number, number] => [point.x + this.offset.x, point.y + this.offset.y]);
		let p1 = path.points.map((point: Position): [number, number] => [point.x + path.offset.x, point.y + path.offset.y]);

		if(applyFix) {
			p0 = expandPoints(this.center.clone().add(this.offset), p0, -POLYGON_PADDING);
			p1 = expandPoints(path.center.clone().add(path.offset), p1, -POLYGON_PADDING);
		}

		return overlap(p0, p1);
	}

	public contains(path: Path, applyFix: boolean = false): boolean {
		if(path === this) {
			return false;
		}

		let p0 = this.points.map((point: Position): [number, number] => [point.x + this.offset.x, point.y + this.offset.y]);
		let p1 = path.points.map((point: Position): [number, number] => [point.x + path.offset.x, point.y + path.offset.y]);

		if(applyFix) {
			p0 = expandPoints(this.center.clone().add(this.offset), p0, POLYGON_PADDING);
			p1 = expandPoints(path.center.clone().add(path.offset), p1, -POLYGON_PADDING);
		}

		return new Polygon(p0).containsPolygon(new Polygon(p1));
	}

	public get topLeft(): Position {
		if(!this.points.length) {
			return null;
		}

		let minX;
		let minY;

		for(const point of this.points) {
			if(minX === undefined || point.x < minX) {
				minX = point.x;
			}

			if(minY === undefined || point.y < minY) {
				minY = point.y;
			}
		}

		return new Position(minX, minY);
	}

	public getFacilities(facilities: IFacility[]): IFacility[] {
		const map = new Map<number, IFacility>();
		for(const facility of facilities) {
			map.set(facility.id, facility);
		}

		const facilitiesArr = [];
		for(const facilityId of Array.from(this.facilities)) {
			if(map.has(facilityId)) {
				facilitiesArr.push(map.get(facilityId));
			}
		}

		return facilitiesArr;
	}

}
