import { Builder } from "../builder";
import { Measure } from "../objects/measure";
import { Path } from "../objects/path";
import { Rotation } from "../objects/rotation";
import { Position } from "../utils/position";
import { Tool } from "./tool";

export class Circle extends Tool {

	public isActive: boolean;
	public measure: Measure;
	public rotation: Rotation;

	public constructor() {
		super("circle");

		this.isActive = false;
		this.measure = new Measure(
			new Position(),
			new Position(),
		);

		// full circle rotation
		this.rotation = new Rotation();
		this.rotation.startAngle = 0;
		this.rotation.endAngle = Math.PI * 2;
	}

	public onClick(builder: Builder, pos: Position, isTouch: boolean): void {
		if(this.isActive && !isTouch) {
			const path = this.createPath();

			builder.renderables.add(path);
			builder.emit("add", path);

			this.reset();
		} else {
			builder.applyCanvasPosition(pos);
			builder.grid.applyGrid(pos);

			this.isActive = true;

			// set graphic positions
			this.rotation.position.copy(pos);
			this.measure.a.copy(pos);
			this.measure.b.copy(pos);
		}
	}

	public onZoom(builder: Builder, zoom: number, isTouch: boolean): void {
		if(isTouch) {
			this.reset();
		}
	}

	public onMove(builder: Builder, pos: Position, movement: Position, start: Position, isTouch: boolean): void {
		if(this.isActive) {
			builder.applyCanvasPosition(pos);
			builder.grid.applyGrid(pos);

			this.measure.b.copy(pos);
			this.rotation.distance = this.measure.a.distance(this.measure.b);
		}
	}

	public onStop(builder: Builder, pos: Position, isTouch: boolean): void {
		if(this.isActive && isTouch) {
			this.reset();
		}
	}

	public enable(builder: Builder): void {
		if(!builder.renderables.has(this.measure)) {
			builder.renderables.add(this.measure);
		}

		if(!builder.renderables.has(this.rotation)) {
			builder.renderables.add(this.rotation);
		}
	}

	public disable(builder: Builder): void {
		if(this.isActive) {
			this.reset();

			if(builder.tool === "delete") {
				builder.setTool(this.name);
			}
		}
	}

	protected reset(): void {
		this.measure.a.zero();
		this.measure.b.zero();
		this.rotation.distance = 0;

		// set inactive
		this.isActive = false;
	}

	protected createPath(): Path {
		const path = new Path();
		const points = 100;
		const radius = this.measure.a.distance(this.measure.b);

		for(let i = 0; i < points; i++) {
			const angle = (Math.PI * 2) / points * i;
			const sin = Math.sin(angle);
			const cos = Math.cos(angle);

			const px = radius * cos + this.measure.a.x;
			const py = radius * sin + this.measure.a.y;

			path.points.push(new Position(px, py));
		}

		// update the path
		path.area = Math.round((Math.PI * radius ** 2) * 1000) / 1000;
		path.center.x = this.measure.a.x;
		path.center.y = this.measure.a.y;

		return path;
	}

}
