import { EventEmitter } from "events";
import $ from "jquery";
import { Builder } from "./builder";

export class History extends EventEmitter {

	public builder: Builder;
	public el: JQuery<Element>;

	private $onlyExit: boolean;

	public constructor(builder: Builder) {
		super();
		this.builder = builder;
		this.$onlyExit = false;
		this.renderEl();
	}

	public disable(tool: string): void {
		if(!!tool) {
			this.el.find(`[data-tool="${tool}"]`).prop("disabled", "disabled");
		} else {
			this.el.find("[data-tool]").prop("disabled", "disabled");
		}

		this.emit("disable", tool);
	}

	public enable(tool?: string): void {
		if(!!tool) {
			this.el.find(`[data-tool="${tool}"]`).prop("disabled", null);
		} else {
			this.el.find("[data-tool]").prop("disabled", null);
		}

		this.emit("enable", tool);
	}

	public renderEl(): void {
		this.builder.el.append(this.el = $(`
			<div class="rb-history rb--hidden">
				<div class="rb-history__inner rb-panel">
					<button type="button" title="Undo" class="rb-history__item ${this.onlyExit ? "rb--hidden" : ""}" data-tool="undo">
						<i class="fal fa-undo"></i>
					</button>
					<button type="button" title="Cancel" class="rb-history__item" data-tool="exit">
						<i class="fal fa-times fa-lg"></i>
					</button>
					<button type="button" title="Redo" class="rb-history__item ${this.onlyExit ? "rb--hidden" : ""}" data-tool="redo">
						<i class="fal fa-redo"></i>
					</button>
				</div>
			</div>
		`));

		this.el.find("[data-tool]").click((event: JQuery.ClickEvent) => {
			this.emit($(event.currentTarget).attr("data-tool"));
		});

		this.emit("render");
	}

	public get onlyExit(): boolean {
		return this.$onlyExit;
	}

	public set onlyExit(onlyExit: boolean) {
		this.$onlyExit = onlyExit = !!onlyExit;

		if(this.el) {
			const tools = this.el.find('[data-tool="undo"], [data-tool="redo"]');
			if(onlyExit) {
				tools.addClass("rb--hidden");
			} else {
				tools.removeClass("rb--hidden");
			}
		}
	}

	public get hidden(): boolean {
		return this.el.hasClass("rb--hidden");
	}

	public set hidden(hidden: boolean) {
		if(hidden) {
			this.el.addClass("rb--hidden");
		} else {
			this.el.removeClass("rb--hidden");
		}
	}

}
