import { Builder } from "./builder";
import { Position } from "./utils/position";
import { FEET_TO_METER } from "./utils/units";
import { INCH_TO_METER } from "./utils/units";
import { CM_TO_METER } from "./utils/units";

export class Grid {

	public readonly builder: Builder;

	public x: number;
	public y: number;
	public zoom: number;

	public constructor(builder: Builder) {
		this.builder = builder;
		this.x = 0;
		this.y = 0;
	}

	public applyTransform(): void {
		const ctx = this.builder.ctx;

		ctx.save();
		ctx.scale(this.zoom, this.zoom);
		ctx.translate(-this.x, -this.y);
	}

	public undoTransform(): void {
		const ctx = this.builder.ctx;

		ctx.save();
		ctx.translate(this.x, this.y);
		ctx.scale(1 / this.zoom, 1 / this.zoom);
	}

	public render(): void {
		const ctx = this.builder.ctx;
		const width = this.builder.canvas.width;
		const height = this.builder.canvas.height;

		if(!this.zoom) {
			this.zoom = Math.min(
				width / 15,
				height / 15,
			);
		}

		ctx.save();
		ctx.scale(this.zoom, this.zoom);

		const zoomWidth = width / this.zoom;
		const zoomHeight = height / this.zoom;

		const halfZoomWidth = zoomWidth / 2;
		const halfZoomHeight = zoomHeight / 2;

		//ctx.fillStyle = "#f7f7f7";
		ctx.fillStyle = "rgba(0, 0, 0, 0.00)";

		ctx.fillRect(
			-halfZoomWidth,
			-halfZoomHeight,
			zoomWidth,
			zoomHeight,
		);

		ctx.strokeStyle = "rgba(0, 0, 0, 0.075)";
		ctx.lineWidth = 2 / this.zoom;

		const renderGrid = (gridSize: number): void => {
			const offsetX = -this.x % gridSize;
			const offsetY = -this.y % gridSize;

			const numBarsX = Math.ceil(zoomWidth / gridSize) + 2;
			const numBarsY = Math.ceil(zoomHeight / gridSize) + 2;

			if(numBarsX > width * 0.15 || numBarsY > height * 0.15) {
				return;
			}

			const halfBarsX = Math.ceil(numBarsX / 2);
			const halfBarsY = Math.ceil(numBarsY / 2);

			for(let barX = -halfBarsX; barX < halfBarsX; barX++) {
				const barWidth = offsetX + barX * gridSize;

				ctx.beginPath();
				ctx.moveTo(barWidth, -halfZoomHeight);
				ctx.lineTo(barWidth, zoomHeight);
				ctx.closePath();
				ctx.stroke();
			}

			for(let barY = -halfBarsY; barY < halfBarsY; barY++) {
				const barHeight = offsetY + barY * gridSize;

				ctx.beginPath();
				ctx.moveTo(-halfZoomWidth, barHeight);
				ctx.lineTo(zoomWidth, barHeight);
				ctx.closePath();
				ctx.stroke();
			}
		};
		/*
		if(this.builder.unit === "m") {
			renderGrid(0.1);
			renderGrid(1);
			renderGrid(10);
			renderGrid(100);
		} else if(this.builder.unit ==="ft") {
			renderGrid(FEET_TO_METER);
			renderGrid(FEET_TO_METER * 3);
			renderGrid(FEET_TO_METER * 3 * 10);
			renderGrid(FEET_TO_METER * 3 * 100);
		}
		*/
		if(this.builder.unit === "m") {
			renderGrid(0.1);
			renderGrid(1);
			renderGrid(10);
			renderGrid(100);
		} else if(this.builder.unit ==="ft") {
			renderGrid(FEET_TO_METER * 3 * 0.1);
			renderGrid(FEET_TO_METER * 3 * 1);
			renderGrid(FEET_TO_METER * 3 * 10);
			renderGrid(FEET_TO_METER * 3 * 100);
		} else if(this.builder.unit ==="in") {
			renderGrid(INCH_TO_METER * 40 * 0.1);
			renderGrid(INCH_TO_METER * 40 * 1);
			renderGrid(INCH_TO_METER * 40 * 10);
			renderGrid(INCH_TO_METER * 40 * 100);
		} else if(this.builder.unit ==="cm") {
			renderGrid(CM_TO_METER * 90 * 0.1);
			renderGrid(CM_TO_METER * 90 * 1);
			renderGrid(CM_TO_METER * 90 * 10);
			renderGrid(CM_TO_METER * 90 * 100);
		}


		ctx.translate(this.x, this.y);
		ctx.fillStyle = "rgba(0, 0, 0, 0.05)";

		ctx.restore();
	}

	public applyGrid(pos: Position): void {
		if(this.builder.activeKeys.has(16)) {
			return;
		}

		/*
		const isMeters = this.builder.unit === "m";
		let base = isMeters ? 0.1 : FEET_TO_METER;

		if(this.zoom < 15) {
			base = isMeters ? 10 : FEET_TO_METER * 3 * 10;
		} else if(this.zoom < 90) {
			base = isMeters ? 1 : FEET_TO_METER * 3;
		}
		*/
		let base;

		if(this.builder.unit === "m") {
			base = 0.1;
		} else if(this.builder.unit === "ft") {
			base = FEET_TO_METER * 3 * 0.1;
		} else if(this.builder.unit === "in") {
			base = INCH_TO_METER * 40 * 0.1;
		} else if(this.builder.unit === "cm") {
			base = CM_TO_METER * 90 * 0.1;
		}

		if(this.zoom < 15) {
			base = base * 100;
		} else if(this.zoom < 90) {
			base = base * 10;
		}

		pos.x = Math.round(pos.x / base) * base;
		pos.y = Math.round(pos.y / base) * base;
	}

}
