import Currencies from "./currencies.json";

export interface ICurrency {
	name: string;
	title: string;
	html: string;
	position: "before" | "after";
}

const CACHE = new Map<string, ICurrency>();
for(const currency of Currencies) {
	CACHE.set(currency.name, currency as ICurrency);
}

export function getAll(): ICurrency[] {
	return Currencies as ICurrency[];
}

export function get(name: string): ICurrency {
	return CACHE.get(name) || null;
}
