export function escapeHTML(str: string): string {
	str = str + "";
	return str.replace(/[&<>"']/g, match => {
		switch(match) {
			case "&":
				return "&amp;";
			case "<":
				return "&lt;";
			case ">":
				return "&rt;";
			case "\"":
				return "&quot;";
			case "'":
				return "&#039;";
			default:
				return match;
		}
	});
}
