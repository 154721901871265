import { Builder } from "../builder";
import { Rotation } from "../objects/rotation";
import { Position } from "../utils/position";
import { Tool } from "./tool";

function roundAngle(angle: number): number {
	const rad2deg = Math.PI / 180;
	return Math.round(angle / rad2deg) * rad2deg;
}

export class Rotate extends Tool {

	public rotating: boolean;
	public rotation: Rotation;

	public constructor() {
		super("rotate");
		this.rotating = false;
		this.rotation = new Rotation();
	}

	public onClick(builder: Builder, pos: Position, isTouch: boolean): void {
		builder.applyCanvasPosition(pos);

		const target = builder.target;

		if(target) {
			if(this.rotating) {
				this.rotating = false;
				this.rotation.distance = 0;

				target.applyAngle();

				builder.setTarget(null);
				builder.emit("rotated", target);
			} else {
				let maxDist = 0;

				target.points.forEach((point: Position) => {
					const dist = target.center.distance(point);
					if(dist > maxDist) {
						maxDist = dist;
					}
				});

				maxDist += 1;

				this.rotating = true;
				this.rotation.distance = maxDist;
				this.rotation.startAngle = target.center.angle(pos);
			}
		} else {
			const paths = builder.findPaths(pos);
			if(paths.length) {
				const path = paths[0];

				builder.setTarget(path);
				this.rotation.position.copy(path.center);
			}
		}
	}

	public onMove(builder: Builder, pos: Position, movement: Position, start: Position, isTouch: boolean): void {
		builder.applyCanvasPosition(pos);

		const target = builder.target;

		if(target && this.rotating) {
			const angle = target.center.angle(pos);

			this.rotation.endAngle = angle;
			target.angle = roundAngle(angle - this.rotation.startAngle);

			builder.emit("rotate", target);
		}
	}

	public enable(builder: Builder): void {
		if(!builder.renderables.has(this.rotation)) {
			builder.renderables.add(this.rotation);
		}
	}

	public disable(builder: Builder): void {
		if(this.rotating) {
			this.rotating = false;
			this.rotation.distance = 0;

			const target = builder.target;
			target.angle = 0;
			builder.setTarget(null);
			builder.emit("rotated", target);
		}
	}

}
