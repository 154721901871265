import { Circle } from "./circle";
import { Delete } from "./delete";
import { Draw } from "./draw";
import { Move } from "./move";
import { MoveProxy } from "./moveProxy";
import { Rotate } from "./rotate";
import { Select } from "./select";
import { Square } from "./square";
import { Tool } from "./tool";

export const Tools: { [key: string]: Tool } = {
	circle: new MoveProxy(new Circle()),
	rotate: new MoveProxy(new Rotate()),
	select: new MoveProxy(new Select()),
	square: new MoveProxy(new Square()),
	delete: new MoveProxy(new Delete()),
	draw: new MoveProxy(new Draw()),
	move: new Move(),
};
