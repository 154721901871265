// @ts-ignore
import { EventEmitter } from "events";
import $ from "jquery";
import { Builder } from "./builder";

export class Menu extends EventEmitter {

	public readonly builder: Builder;

	public el: JQuery<Element>;
	public hidden: boolean;
	public items: Menu.IItem[];
	public x: number;
	public y: number;

	public constructor(builder: Builder, {
		hidden = false,
		items = [],
		x = 0,
		y = 0,
	}: Menu.IOptions = {}) {
		super();

		this.builder = builder;
		this.hidden = hidden;
		this.items = items;
		this.x = x;
		this.y = y;

		this.renderEl();
	}

	public remove(): void {
		if (this.el) {
			this.el.addClass("rb--hidden");

			setTimeout(() => {
				this.el.remove();
				this.el = null;
			}, 150);
		}
	}

	public renderEl(): void {
		if (this.el) {
			this.el.remove();
			this.el = null;
		}

		const items = this.items.filter(item => !item.hidden);
		if (!items.length) {
			return;
		}

		this.builder.el.append(this.el = $(`
			<div class="rb-menu rb-panel rb--hidden" style="top: ${this.y}px; left: ${this.x}px;">
				${items.map(item => `
					<div class="rb-menu__item" data-name="${item.name}">
						<span class="rb-menu__item-icon">
							<i class="${item.icon}"></i>
						</span>
						<span class="rb-menu__item-text">${item.text}</span>
					</div>
				`).join("")}
			</div>
		`));

		setTimeout(() => {
			if (!this.hidden) {
				this.el.removeClass("rb--hidden");
			}
		}, 0);

		this.el.find(".rb-menu__item").click(event => {
			const el = $(event.currentTarget);
			const name = el.attr("data-name");

			this.emit("click", name);

			for (const item of this.items) {
				if (item.name === name) {
					if (!!item.callback && typeof item.callback === "function") {
						item.callback();
					}
					break;
				}
			}
		});
	}

}

export namespace Menu {

	export interface IOptions {
		hidden?: boolean;
		items?: Menu.IItem[];
		x?: number;
		y?: number;
	}

	export interface IItem {
		name: string;
		icon: string;
		text: string;
		disabled?: boolean;
		hidden?: boolean;
		callback?: () => void;
	}

}
