
import { EventEmitter } from "events";
import { Builder } from "./builder";
import * as Currency from "./utils/currency";
import { escapeHTML } from "./utils/html";

export class Popup extends EventEmitter {

	public builder: Builder;
	public el: JQuery<HTMLElement>;
	public title: string;
	public content: string | Popup.IComponent[];
	public actions: Popup.IAction[];
	public ignoreBlocker: boolean;
	public inputMap: Map<string, Popup.IComponent>;

	public constructor(builder: Builder, {
		title = null,
		content = [],
		actions = [],
		ignoreBlocker = true,
	}: Popup.IOptions = { }) {
		super();

		this.builder = builder;
		this.el = null;
		this.title = title;
		this.content = content;
		this.actions = actions;
		this.ignoreBlocker = ignoreBlocker;
		this.inputMap = new Map();

		for(const component of this.content) {
			switch(component.type) {
				case "input":
				case "select":
				case "checkbox":
				case "price":
				case "textarea":
					this.inputMap.set(component.name, component);
					break;
			}
		}
	}

	public renderEl(): void {
		if(this.el) {
			this.el.remove();
		}

		this.el = $(`
			<div class="rb-popup__wrapper rb--hidden">
				<div class="rb-popup rb-panel">
					${this.title ? `
						<div class="rb-popup__header">
							<span class="rb-popup__title"></span>
						</div>
					` : ``}
					${this.content.length ? `
						<div class="rb-popup__content"></div>
					` : ``}
					${this.actions.length ? `
						<div class="rb-popup__actions"></div>
					` : ``}
				</div>
			</div>
		`);

		if(!!this.title) {
			const title = this.el.find(".rb-popup__title");
			title.html(this.title);
		}

		if(!!this.content) {
			const content = this.el.find(".rb-popup__content");

			if(typeof this.content === "string") {
				content.html(this.applyVariables(this.content));
			} else if(this.content.length) {
				for(const component of this.content) {
					const el = $("<div class=\"rb-popup__component\"></div>");

					if(component.type === "text") {
						if(!!component.html) {
							el.html(this.applyVariables(component.html));
						} else if(!!component.text) {
							el.text(this.applyVariables(component.text));
						}
					} else if(component.type === "input") {
						el.html(`
							<input name="rb[${component.name}]"
								placeholder="${component.placeholder === undefined ? "" : this.applyVariables(component.placeholder)}"
								value="${component.value === undefined ? "" : this.applyVariables(component.value)}">
						`);
					} else if(component.type === "textarea") {
						el.html(`<textarea name="rb[${component.name}]" ${component.columns ? `cols="${component.columns - 0}"` : ``} ${component.rows ? `rows="${component.rows - 0}"` : ``}>${escapeHTML(this.applyVariables(component.value))}</textarea>`);
					} else if(component.type === "select") {
						let options: Array<{ label: string, value: any }> = [];

						if(Array.isArray(component.options)) {
							options = component.options;
						} else if(typeof component.options === "function") {
							options = component.options.call(this);
						}

						el.html(`
							<select name="rb[${component.name}]">
								${options.map(option => `
									<option ${option.value ? `value="${escapeHTML(this.applyVariables(option.value))}"` : ``} ${(option.value && option.value === component.value) || (option.label === component.value) ? `selected="selected"` : ``}>
										${escapeHTML(this.applyVariables(option.label))}
									</option>
								`).join("")}
							</select>
						`);
					} else if(component.type === "checkbox") {
						let options: Array<{ label: string, value: any }> = [];

						if(Array.isArray(component.options)) {
							options = component.options;
						} else if(typeof component.options === "function") {
							options = component.options.call(this);
						}

						el.html(`
							${options.map(option => `
								<label class="rb-checkbox" ${component.columns ? `style="width: ${100 / component.columns | 0}%"` : ``}>
									<input type="checkbox" name="rb[${component.name}]" value="${escapeHTML(this.applyVariables(option.value))}" ${option.value === component.value ? `checked="checked"` : ``}> ${escapeHTML(this.applyVariables(option.label))}
									<span class="rb-checkbox__checkmark"></span>
								</label>
							`).join("")}
						`);
					} else if(component.type === "price") {
						el.html(`
							<div class="rb-price">
								<div class="rb-price__currency">
									<div class="rb-flag" data-currency="eur"></div>
									<select name="rb[${component.name}_currency]">
										${Currency.getAll().map(currency => `
											<option
												value="${currency.name}"
												title="${currency.title}"
												${currency.name === "EUR" ? `selected="selected"` : ``}
											>
												${currency.name} (${currency.html})
											</option>
										`).join("")}
									</select>
								</div>
								<input
									name="rb[${component.name}_amount]"
									placeholder="${component.placeholder === undefined ? "" : component.placeholder}"
									value="${component.value === undefined ? "" : component.value}">
							</div>
						`);

						el.find(".rb-price select").change(function() {
							el.find(".rb-price .rb-flag").attr("data-currency", ($(this).val() as string).toLowerCase());
						});
					}

					el.addClass(`rb-popup__component--${component.type}`);

					content.append(el);
				}
			}
		}

		if(this.actions.length) {
			const actions = this.el.find(".rb-popup__actions");

			for(const action of this.actions) {
				const el = $(`
					<button type="button" class="rb-popup__action">
						${escapeHTML(action.name)}
					</button>
				`);

				if(action.type === "close") {
					el.attr("data-action", "close");
				} else if(action.type === "event") {
					el.attr({
						"data-action": "event",
						"data-event": action.event,
					});
				}

				el.click(this.onClick.bind(this));

				actions.append(el);
			}
		}

		this.el.click(event => {
			if(this.el.is(event.target) && !this.ignoreBlocker) {
				this.close();
			}
		});

		this.builder.el.append(this.el);
		this.emit("renderEl", this.el);
	}

	public applyVariables(str: string): string {
		return (str + "")
			.replace("{{unit}}", this.builder.unit)
			.replace("{{tool}}", this.builder.tool);
	}

	public setValues(data: { [input: string]: any }): void {
		for(const input in data) {
			if(data.hasOwnProperty(input)) {
				this.setValue(input, data[input]);
			}
		}
	}

	public setValue(name: string, value: any): void {
		if(!this.el) {
			return;
		}

		const component = this.inputMap.get(name);

		if(component && component.type === "price") {
			const currency = this.el.find(`[name="rb[${name}_currency]"]`);
			const amount = this.el.find(`[name="rb[${name}_amount]"]`);

			currency.val(value.currency || "EUR").trigger("change");
			amount.val(value.amount || "");
		} else {
			const input = this.el.find(`[name="rb[${name}]"]`);

			switch(component ? component.type : null) {
				case "checkbox":
					input.prop("checked", null);
					for(const val of value) {
						input.filter(`[value="${val}"]`).prop("checked", "checked");
					}
					break;
				case "textarea":
					input.text(value || "");
					break;
				default:
					input.val(value || "");
			}
		}
	}

	public onClick(event: Event) {
		event.preventDefault();
		event.stopPropagation();

		const el = $(event.currentTarget);

		if(el.attr("data-action") === "close") {
			this.close();
		} else if(el.attr("data-action") === "event") {
			const values: { [key: string]: string | string[] | { currency: string, amount: number } } = { };

			for(const component of this.content) {
				if(typeof component !== "object") {
					continue;
				}

				if(component.type === "input" || component.type === "select" || component.type === "textarea") {
					const value = this.el.find(`[name="rb[${component.name}]"]`).val() as string;
					values[component.name] = value;
				} else if(component.type === "checkbox") {
					values[component.name] = [];

					const checkboxes = this.el.find(`[name="rb[${component.name}]"]:checked`);
					const checkboxValues = values[component.name] as string[];

					checkboxes.each(function() {
						checkboxValues.push($(this).val() + "");
					});

					values[component.name] = checkboxValues;
				} else if(component.type === "price") {
					const currency = this.el.find(`[name="rb[${component.name}_currency]"]`).val() as string;
					const amount = this.el.find(`[name="rb[${component.name}_amount]"]`).val() as any - 0;

					values[component.name] = { currency, amount };
				}
			}

			this.emit(el.attr("data-event"), values);
		}
	}

	public close(): void {
		if(!this.el) {
			return;
		}

		this.emit("close");
		this.el.addClass("rb--hidden");
		this.el.find("input, select, textarea").attr("disabled", "disabled");
	}

	public open(): void {
		if(!this.el) {
			return;
		}

		this.emit("open");
		this.el.removeClass("rb--hidden");
		this.el.find("input, select, textarea").attr("disabled", null);
	}

	public get isOpen(): boolean {
		return !!this.el ? !this.el.hasClass("rb--hidden") : false;
	}

	public reset(): void {
		if(!this.el) {
			return;
		}

		for(const component of this.content) {
			if(typeof component !== "string" && !!component.name) {
				if(component.type === "checkbox") {
					this.el.find(`[name="rb[${component.name}]"]:checked`).prop("checked", "");
				} else if(component.type !== "text") {
					this.el.find(`[name="rb[${component.name}]"]`).val("");
				}
			}
		}

		this.emit("reset");
	}

}

export namespace Popup {

	export interface IOptions {
		title?: string;
		content?: IComponent[];
		actions?: IAction[];
		ignoreBlocker?: boolean;
	}

	export interface IComponent {
		type: "input" | "select" | "text" | "checkbox" | "price" | "textarea";
		name?: string;
		text?: string;
		html?: string;
		placeholder?: string;
		value?: any;
		options?: Array<{ label: string, value: any }> | (() => Array<{ label: string, value: any }>);
		rows?: number;
		columns?: number;
		//currencies?: Array<Currency.ICurrency>
	}

	export interface IAction {
		type: "close" | "event";
		name: string;
		event?: string;
	}

}
