import { Builder } from "../builder";
import { Position } from "../utils/position";

export abstract class Tool {

	public readonly name: string;
	public forceMove: boolean;

	public constructor(name: string) {
		this.name = name;
		this.forceMove = false;
	}

	// tslint:disable-next-line: no-empty
	public onKeyUp(builder: Builder, keyCode: number): void { }

	// tslint:disable-next-line: no-empty
	public onKeyDown(builder: Builder, keyCode: number): void { }

	// tslint:disable-next-line: no-empty
	public onClick(builder: Builder, pos: Position, isTouch: boolean): void { }

	// tslint:disable-next-line: no-empty
	public onZoom(builder: Builder, zoom: number, isTouch: boolean): void { }

	// tslint:disable-next-line: no-empty
	public onMove(builder: Builder, pos: Position, movement: Position, start: Position, isTouch: boolean): void { }

	// tslint:disable-next-line: no-empty
	public onStop(builder: Builder, pos: Position, isTouch: boolean): void { }

	// tslint:disable-next-line: no-empty
	public enable(builder: Builder): void { }

	// tslint:disable-next-line: no-empty
	public disable(builder: Builder): void { }

	// tslint:disable-next-line: no-empty
	public render(builder: Builder, delta: number): void { }

}
