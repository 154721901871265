import intersect from "lines-intersect";
import inside from "point-inside-polygon";

type Point = [number, number];
type Polygon = Point[];

export function polygonPointsInside(p0: Polygon, p1: Polygon): boolean {
	for(const point of p0) {
		if(inside(point, p1)) {
			return true;
		}
	}

	for(const point of p1) {
		if(inside(point, p0)) {
			return true;
		}
	}

	return false;
}

export function polygonEdgesOverlap(p0: Polygon, p1: Polygon): boolean {
	for(let i = 0; i < p0.length - 1; i++) {
		for(let j = 0; j < p1.length - 1; j++) {
			if(intersect(p0[i][0], p0[i][1], p0[i + 1][0], p0[i + 1][1], p1[j][0], p1[j][1], p1[j + 1][0], p1[j + 1][1])) {
				return true;
			}
		}
	}

	return false;
}

export function overlap(p0: Polygon, p1: Polygon): boolean {
	if(polygonPointsInside(p0, p1)) {
		return true;
	}

	if(polygonEdgesOverlap(p0, p1)) {
		return true;
	}

	return false;
}
