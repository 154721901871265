import { Builder } from "../builder";
import { Measure } from "../objects/measure";
import { Path } from "../objects/path";
import { Position } from "../utils/position";
import { Tool } from "./tool";

const KEY_EXIT = 27;

export class Square extends Tool {

	public startPosition: Position;
	public endPosition: Position;
	public measures: {
		top: Measure,
		left: Measure,
		bottom: Measure,
		right: Measure,
	};

	public constructor() {
		super("square");

		this.startPosition = null;
		this.endPosition = new Position();
	}

	public onKeyUp(builder: Builder, keyCode: number): void {
		if(!this.startPosition) {
			return;
		}

		if(keyCode === KEY_EXIT) {
			this.exit();
		}

		this.updateHistory(builder);
	}

	public exit(): void {
		this.startPosition = null;
		this.measures = null;
	}

	public onClick(builder: Builder, pos: Position, isTouch: boolean): void {
		if(this.startPosition && !isTouch) {
			const path = new Path([
				this.measures.top.a,
				this.measures.bottom.a,
				this.measures.bottom.b,
				this.measures.top.b,
			]);

			if(path.area > 0) {
				builder.renderables.add(path);
				builder.emit("add", path);
			}

			this.exit();
			this.updateHistory(builder);
		} else {
			this.startPosition = builder.applyCanvasPosition(pos);

			builder.history.onlyExit = true;
			builder.history.hidden = false;

			builder.grid.applyGrid(this.startPosition);

			this.measures = {
				top: new Measure(this.startPosition.clone(), this.startPosition.clone()),
				bottom: new Measure(this.startPosition.clone(), this.startPosition.clone()),
				left: new Measure(this.startPosition.clone(), this.startPosition.clone()),
				right: new Measure(this.startPosition.clone(), this.startPosition.clone()),
			};
		}
	}

	public onZoom(builder: Builder, zoom: number, isTouch: boolean): void {
		if(isTouch) {
			this.startPosition = null;
		}
	}

	public onMove(builder: Builder, pos: Position, movement: Position, start: Position, isTouch: boolean): void {
		if(this.startPosition) {
			builder.applyCanvasPosition(this.endPosition.copy(pos));
			builder.grid.applyGrid(this.endPosition);

			const top = Math.min(this.startPosition.y, this.endPosition.y);
			const bottom = Math.max(this.startPosition.y, this.endPosition.y);
			const left = Math.min(this.startPosition.x, this.endPosition.x);
			const right = Math.max(this.startPosition.x, this.endPosition.x);

			this.measures.top.a.set(left, top);
			this.measures.top.b.set(right, top);

			this.measures.bottom.a.set(left, bottom);
			this.measures.bottom.b.set(right, bottom);

			this.measures.left.a.set(left, top);
			this.measures.left.b.set(left, bottom);

			this.measures.right.a.set(right, top);
			this.measures.right.b.set(right, bottom);
		}
	}

	public onStop(builder: Builder, pos: Position, isTouch: boolean): void {
		if(this.startPosition && isTouch) {
			const path = new Path([
				this.measures.top.a,
				this.measures.bottom.a,
				this.measures.bottom.b,
				this.measures.top.b,
			]);

			if(path.area > 0) {
				builder.renderables.add(path);
				builder.emit("add", path);
			}

			this.exit();
		}
	}

	public enable(builder: Builder): void {
		builder.history.on("exit", () => {
			this.startPosition = null;
			this.measures = null;
			this.updateHistory(builder);
		});
	}

	public disable(builder: Builder): void {
		if(this.startPosition) {
			this.exit();

			if(builder.tool === "delete") {
				builder.setTool(this.name);
			}
		}

		builder.history.hidden = true;
		builder.history.removeAllListeners();
	}

	public render(builder: Builder): void {
		if(this.startPosition) {
			builder.grid.applyTransform();

			this.measures.top.render(builder);
			this.measures.bottom.render(builder);
			this.measures.left.render(builder);
			this.measures.right.render(builder);

			builder.ctx.restore();
		}
	}

	protected updateHistory(builder: Builder): void {
		if(this.startPosition) {
			builder.history.onlyExit = true;
			builder.history.hidden = false;
		} else {
			builder.history.hidden = true;
		}
	}

}
