import { EventEmitter } from "events";
import $ from "jquery";
import throttle from "lodash.throttle";
import Polygon from "polygon";
import { Controller, IFacility } from "./controller";
import { Grid } from "./grid";
import { History } from "./history";
import { Path } from "./objects/path";
import { Tools } from "./tools";
import { Position } from "./utils/position";
import { IRenderable } from "./utils/renderable";

export class Builder extends EventEmitter {


	static async create(el: string, {
		tool = "select",
		unit = "m",
		viewer = false,
		recenter = false,
	}: Builder.IOptions = {}) {
		const o = new Builder(el, {tool, unit, viewer, recenter});
		await o.controller.init(o.viewer ? Builder.Mode.VIEW : Builder.Mode.DRAW);
		return o;
	}


	public readonly controller: Controller;
	public readonly el: JQuery<HTMLElement>;
	public readonly canvas: HTMLCanvasElement;
	public readonly ctx: CanvasRenderingContext2D;
	public readonly grid: Grid;
	public readonly history: History;

	public mode: Builder.Mode;
	public tool: string;
	public viewer: boolean;
	public unit: "m" | "ft" | "cm" | "in";
	public target: Path;
	public renderables: Set<IRenderable>;
	public isDone: boolean;
	public queueRecenter: boolean;

	public activeTouches: Map<number, Touch>;
	public activeKeys: Set<number>;

	protected touchDistance: number;
	protected touchStartPosition: Position;
	protected touchPosition: Position;

	protected isMouseDown: boolean;
	protected mouseStartPosition: Position;
	protected mousePosition: Position;

	public constructor(el: string, {
		tool = "select",
		unit = "m",
		viewer = false,
		recenter = false,
	}: Builder.IOptions = {}) {
		super();

		this.el = $("#" + el);
		this.canvas = document.createElement("canvas");
		this.ctx = this.canvas.getContext("2d");
		this.history = new History(this);

		this.tool = tool;
		this.unit = unit;
		this.viewer = !!viewer;
		this.queueRecenter = !!recenter;

		this.controller = new Controller(this);
		this.setMode(Builder.Mode.VIEW);

		// send init request
		//this.controller.init(this.viewer ? Builder.Mode.VIEW : Builder.Mode.DRAW);

		this.grid = new Grid(this);
		this.activeTouches = new Map();
		this.activeKeys = new Set();
		this.renderables = new Set();
		this.target = null;

		this.isMouseDown = false;
		this.mouseStartPosition = null;
		this.mousePosition = new Position();

		this.touchStartPosition = null;
		this.touchPosition = new Position();

		this.loop();
		this.registerEvents();
	}



	public get hidden(): boolean {
		return $(this.el).is(":hidden");
	}

	public renderEl(): void {
		this.el.addClass("rb");
		this.el.html(`
			<div class="rb-units">
				<div class="rb-units__inner rb-panel">
					<button type="button" title="Switch to metres" class="rb-units__item" data-unit="m">
						<span>m&sup2;</span>
					</button>
					<button type="button" title="Switch to feet" class="rb-units__item" data-unit="ft">
						<span>ft&sup2;</span>
					</button>
					<button type="button" title="Switch to centimetres" class="rb-units__item" data-unit="cm">
						<span>cm&sup2;</span>
					</button>
					<button type="button" title="Switch to inches" class="rb-units__item" data-unit="in">
						<span>in&sup2;</span>
					</button>
				</div>
			</div>
			${this.mode === Builder.Mode.DRAW ? `
				<div class="rb-tools">
					<div class="rb-tools__inner rb-panel">
						<button type="button" class="rb-tools__item" data-tool="select" title="Select Tool">
							<i class="fal fa-mouse-pointer"></i>
						</button>
						<button type="button" class="rb-tools__item" data-tool="move" title="Move Tool">
							<i class="fal fa-arrows"></i>
						</button>
						<!--<button type="button" class="rb-tools__item" data-tool="rotate" title="Rotate Tool">
							<i class="fal fa-sync"></i>
						</button>-->
						<button type="button" class="rb-tools__item" data-tool="draw" title="Draw Tool">
							<i class="fal fa-pencil"></i>
						</button>
						<button type="button" class="rb-tools__item" data-tool="square" title="Rectangle Tool">
							<i class="fal fa-square"></i>
						</button>
						<button type="button" class="rb-tools__item" data-tool="circle" title="Circle Tool">
							<i class="fal fa-circle"></i>
						</button>
						<button type="button" class="rb-tools__item" data-tool="delete" title="Delete Tool">
							<i class="fal fa-trash"></i>
						</button>
					</div>
				</div>
				<div class="rb-help__prompt rb-panel">
					<div class="rb-stage--floor">
						<span class="rb-help__prompt-title"><strong>Step 1:</strong> Draw Floor Plan</span>
						<p>To get started, please draw out a floor plan using the tools provided.</p>
						<p>While you draw out areas, you can name and specify the exact dimensions of the floor/building.</p>
						<p>When you're ready to continue, press "Next".</p>
					</div>
					<div class="rb-stage--area">
						<span class="rb-help__prompt-title"><strong>Step 2:</strong> Draw Areas</span>
						<p>Please draw out the individual areas you'd like to sell. <em>Make sure they do not overlap.</em></p>
						<p>You can specify any benefits/facilities available for this area separately.</p>
						<p>Press "Next" and done!</p>
					</div>
					<div class="rb-stage--done">
						<span class="rb-help__prompt-title"><strong>All done!</strong></span>
						<p>Your floor plan has been set up.</p>
					</div>
				</div>
				<button type="button" class="rb-help__btn rb-panel" title="Not sure what to do? Click the button for help.">
					<i class="fal fa-question"></i>
				</button>
			` : ``}
			${!this.viewer ? `
				<div class="rb-controls">
					<button type="button" class="rb-prev rb-controls__btn" disabled="disabled">
						<i class="fal fa-arrow-left"></i> Previous
					</button>
					<button type="button" class="rb-next rb-controls__btn" disabled="disabled">
						Next <i class="fal fa-arrow-right"></i>
					</button>
				</div>
			` : ``}
		`);

		this.el.prepend(this.canvas);
		this.history.renderEl();

		this.el.find(".rb-tools .rb-tools__item[data-tool]").click(event => {
			const tool = $(event.currentTarget).attr("data-tool");
			if (tool && this.tool !== tool) {
				this.setTool(tool);
			}
		});

		this.el.find(".rb-units .rb-units__item[data-unit]").click(event => {
			const unit = $(event.currentTarget).attr("data-unit") as "m" | "ft" | "cm" | "in";
			if (unit && this.unit !== unit) {
				this.setUnit(unit);
			}
		});

		this.el.find(".rb-help__btn").click(() => {
			this.el.find(".rb-help__prompt")
				.toggleClass("rb--hidden");
		});

		this.el.find(".rb-next").click(() => {
			this.setTarget(null);
			this.emit("blur");
			this.emit("next");
		});

		this.el.find(".rb-prev").click(() => {
			this.setTarget(null);
			this.emit("blur");
			this.emit("prev");
		});

		if (this.controller) {
			this.controller.renderEl();
		}
	}

	public setMode(mode: Builder.Mode): void {
		if (this.mode === mode) {
			return;
		}

		this.mode = mode;
		this.renderEl();

		if (this.mode === Builder.Mode.VIEW) {
			this.setTool("select");
		} else {
			this.updateToolDisplay();
		}

		this.updateUnitDisplay();
	}

	public setCursor(cursor: string): void {
		$(this.canvas).css("cursor", cursor || "");
	}

	public setTarget(path: Path): void {
		for (const arrPath of Array.from(this.renderables)) {
			if (arrPath instanceof Path) {
				arrPath.highlight = false;
			}
		}

		if (this.target) {
			this.target.highlight = false;
		}

		this.target = path;

		if (this.target) {
			this.target.highlight = true;
		}

		this.emit("target", path);
	}

	public getPaths(): Path[] {
		const paths = [];

		for (const path of Array.from(this.renderables.values())) {
			if (!(path instanceof Path)) {
				continue;
			}

			paths.push(path);
		}

		return paths;
	}

	public findPaths(pos: Position, ignore: Path | Path[] = []): Path[] {
		if (this.hidden) {
			return [];
		}

		const lookupPos = { ...pos, w: 0, h: 0};
		const paths = [];

		const renderables = Array.from(this.renderables).filter((renderable: IRenderable) => {
			if (renderable instanceof Path) {
				if (this.viewer && renderable.type === "area") {
					return true;
				} else {
					return !renderable.locked;
				}
			} else {
				return false;
			}
		}).sort((a: Path, b: Path) => {
			if (a.area < b.area) {
				return -1;
			} else if (a.area > b.area) {
				return 1;
			} else {
				return 0;
			}
		});

		for (const path of renderables) {
			if (!(path instanceof Path)) {
				continue;
			}

			if (path === ignore || (Array.isArray(ignore) && ignore.includes(path))) {
				continue;
			}

			if (this.controller.mode === Controller.Mode.FLOOR && path.type === "area") {
				continue;
			}

			const polygon = new Polygon(path.points);

			if (polygon.contains(lookupPos)) {
				paths.push(path);
			}
		}

		return paths;
	}

	public applyCanvasPosition(pos: Position = new Position()): Position {
		const rect = this.canvas.getBoundingClientRect();
		return pos.set(
			((pos.x - rect.left) - this.canvas.width / 2) / this.grid.zoom + this.grid.x,
			((pos.y - rect.top) - this.canvas.height / 2) / this.grid.zoom + this.grid.y,
		);
	}

	public revertCanvasPosition(pos: Position = new Position(), canvasOffset: boolean = true): Position {
		const rect = this.canvas.getBoundingClientRect();
		return pos.set(
			(pos.x - this.grid.x) * this.grid.zoom + this.canvas.width / 2 + (canvasOffset ? rect.left : 0),
			(pos.y - this.grid.y) * this.grid.zoom + this.canvas.height / 2 + (canvasOffset ? rect.left : 0),
		);
	}

	public updateToolDisplay(): void {
		const tools = $(this.el).find(".rb-tools .rb-tools__item");
		tools.removeClass("rb-tools__item--active");

		if (this.tool !== null) {
			const tool = tools.filter(`[data-tool="${this.tool}"]`);
			tool.addClass("rb-tools__item--active");
		}
	}

	public setTool(tool: string): void {
		if (this.tool === tool) {
			return;
		}

		const oldTool = this.tool;
		this.tool = tool;

		if (oldTool !== null) {
			Tools[oldTool].disable(this);
		}

		if (this.tool !== null) {
			Tools[this.tool].enable(this);
		}

		if (!this.controller.isBusy) {
			this.controller.renderEl();
		}

		// adjust display
		this.updateToolDisplay();
	}

	public get isHistoryVisible(): boolean {
		return !$(this.el).find(".rb-history").hasClass("rb--hidden");
	}

	public set isHistoryVisible(visible: boolean) {
		if (!!visible) {
			$(this.el).find(".rb-history").removeClass("rb--hidden");
		} else {
			$(this.el).find(".rb-history").addClass("rb--hidden");
		}
	}

	public updateUnitDisplay(): void {
		const units = $(this.el).find(".rb-units .rb-units__item");
		units.removeClass("rb-units__item--active");

		if (this.unit !== null) {
			const unit = units.filter(`[data-unit="${this.unit}"]`);
			unit.addClass("rb-units__item--active");
		}
	}

	public setUnit(unit: "m" | "ft" | "cm" | "in"): void {
		if (this.unit === unit || this.controller.isBusy) {
			return;
		}

		this.unit = unit;
		this.controller.renderEl();
		this.updateUnitDisplay();
	}

	public registerEvents(): void {
		const canvas = this.canvas;

		canvas.addEventListener("wheel", this.onMouseWheel.bind(this));
		canvas.addEventListener("mousedown", this.onMouseDown.bind(this));
		window.addEventListener("mousemove", throttle(this.onMouseMove.bind(this), 15));
		window.addEventListener("mouseup", this.onMouseUp.bind(this));

		canvas.addEventListener("touchstart", this.onTouchStart.bind(this));
		window.addEventListener("touchmove", throttle(this.onTouchMove.bind(this), 15));
		window.addEventListener("touchend", this.onTouchEnd.bind(this));
		window.addEventListener("touchcancel", this.onTouchEnd.bind(this));

		document.addEventListener("keydown", this.onKeyDown.bind(this));
		document.addEventListener("keyup", this.onKeyUp.bind(this));

		this.on("next:enable", () => {
			const btn = this.el.find(".rb-next");
			if (btn.is(":disabled")) {
				btn.prop("disabled", "");
			}
		});

		this.on("next:disable", () => {
			const btn = this.el.find(".rb-next");
			if (!btn.is(":disabled")) {
				btn.prop("disabled", "disabled");
			}
		});

		this.on("prev:enable", () => {
			const btn = this.el.find(".rb-prev");
			if (btn.is(":disabled")) {
				btn.prop("disabled", "");
			}
		});

		this.on("prev:disable", () => {
			const btn = this.el.find(".rb-prev");
			if (!btn.is(":disabled")) {
				btn.prop("disabled", "disabled");
			}
		});
	}

	public resize(): boolean {
		const canvas = this.canvas;
		const width = canvas.clientWidth;
		const height = canvas.clientHeight;

		if (canvas.width !== width || canvas.height !== height) {
			canvas.width = width;
			canvas.height = height;

			this.emit("resize");
			this.emit("blur");

			return true;
		}

		return false;
	}

	public clear(): void {
		const canvas = this.canvas;
		const ctx = this.ctx;

		const width = canvas.width;
		const height = canvas.height;

		ctx.clearRect(0, 0, width, height);
	}

	public render(delta: number): void {
		if (!this.resize()) {
			this.clear();
		}

		if (this.queueRecenter) {
			this.queueRecenter = false;
			this.recenter();
		}

		this.ctx.save();
		this.ctx.translate(
			this.canvas.width / 2,
			this.canvas.height / 2,
		);

		this.grid.render();

		const renderables = Array.from(this.renderables).sort(function(a, b) {
			const o1 = a.order(this);
			const o2 = b.order(this);

			if (o1 < o2) {
				return -1;
			} else if (o1 > o2) {
				return 1;
			} else {
				return 0;
			}
		});

		this.grid.applyTransform();

		for (const renderable of renderables) {
			renderable.render(this, delta);
		}

		this.ctx.restore();

		if (this.tool) {
			Tools[this.tool].render(this, delta);
		}

		this.ctx.restore();
	}

	public loop(): void {
		this.isDone = false;

		let lastFrame = performance.now();

		const queue = () => {
			const now = performance.now();
			const delta = now - lastFrame;

			lastFrame = now;

			if (!this.hidden) {
				this.render(delta);
			}

			if (this.isDone) {
				return;
			}

			requestAnimationFrame(queue);
		};

		requestAnimationFrame(queue);
	}

	public import(floors: Builder.IFloor[] , recenter: boolean = true): void {
		if (!floors) {
			return;
		}

		this.setTool("select");
		this.renderables.forEach(renderable => {
			if (renderable instanceof Path) {
				this.renderables.delete(renderable);
			}
		});

		if (this.controller) {
			this.controller.infoPopup.close();
		}

		for (const floor of floors) {
			const floorPath = new Path(floor.points.map(point => {
				return new Position(point[0], point[1]);
			}));

			floorPath.type = "floor";
			floorPath.name = floor.name;
			floorPath.area = floor.area;
			floorPath.locked = true;
			floorPath.update();

			this.renderables.add(floorPath);

			for (const area of floor.areas) {
				const areaPath = new Path(area.points.map(point => {
					return new Position(point[0], point[1]);
				}));

				areaPath.type = "area";
				areaPath.name = area.name;
				areaPath.id = area.id;
				areaPath.currency = area.currency;
				areaPath.price = area.price;
				areaPath.fprice = area.fprice;
				areaPath.area = area.area;
				areaPath.facilities = new Set();

				if (area.facilities) {
					area.facilities.forEach(facility => areaPath.facilities.add(facility));
				}

				this.renderables.add(areaPath);
			}
		}

		this.controller.setMode(Controller.Mode.AREA);
		//this.controller.applyAreaDetails();	
		this.controller.updateControls()

		if (recenter)
			this.queueRecenter = true;
	}

	public recenter(margin: number = 2.5): void {
		const paths = this.getPaths();
		const floorPaths = paths.filter(path => path.type === "floor");

		let minX: number;
		let minY: number;
		let maxX: number;
		let maxY: number;

		for (const floorPath of floorPaths) {
			floorPath.points.forEach(point => {
				if (minX === undefined || minX > point.x) {
					minX = point.x;
				}

				if (maxX === undefined || maxX < point.x) {
					maxX = point.x;
				}

				if (minY === undefined || minY > point.y) {
					minY = point.y;
				}

				if (maxY === undefined || maxY < point.y) {
					maxY = point.y;
				}
			});
		}

		console.log("minX: " + minX + ", maxX: " + maxX);
		console.log("minY: " + minY + ", maxY: " + maxY);

		this.grid.x = (maxX + minX) / 2;
		this.grid.y = (maxY + minY) / 2;
		//console.log("grid.x: " + this.grid.x + ", grid.y: " + this.grid.y);

		const width = Math.abs(maxX - minX)  + margin * 2;
		const height = Math.abs(maxY - minY) + margin * 2;

		//console.log("recenter -> before :" + this.grid.zoom);

		this.grid.zoom = Math.min(
			this.canvas.width / width,
			this.canvas.height / height,
		);
		

		//console.log("recenter -> after:" + this.grid.zoom);
	}

	public export(): Builder.IFloor[] {
		const paths = this.getPaths();
		const floorPaths = paths.filter(path => path.type === "floor");
		const areaPaths = paths.filter(path => path.type === "area");

		const floors: Builder.IFloor[] = [];

		for (const floorPath of floorPaths) {
			const floor: Builder.IFloor = {
				name: floorPath.name,
				area: floorPath.area,
				areas: [],
				points: floorPath.points
					.map(point => [point.x, point.y]),
			};

			for (const areaPath of areaPaths) {
				if (!floorPath.contains(areaPath, true)) {
					continue;
				}

				const area: Builder.IArea = {
					name: areaPath.name,
					currency: areaPath.currency,
					price: areaPath.price,
					fprice: areaPath.fprice,
					id: areaPath.id,
					area: areaPath.area,
					facilities: Array.from(areaPath.facilities),
					points: areaPath.points.map(point => [point.x, point.y]),
				};

				if (areaPath.description && typeof areaPath.description === "string") {
					area.description = areaPath.description;
				}

				if (area.facilities && Array.isArray(area.facilities)) {
					area.facilities = area.facilities;
				}

				floor.areas.push(area);
			}

			floors.push(floor);
		}

		return floors;
	}

	public get touchCount(): number {
		return this.activeTouches.size;
	}

	protected onKeyDown(event: KeyboardEvent): void {
		const keyCode = event.keyCode;

		if (!this.activeKeys.has(keyCode)) {
			this.activeKeys.add(keyCode);
		}

		if (this.tool !== null) {
			Tools[this.tool].onKeyDown(this, event.keyCode);
		}
	}

	protected onKeyUp(event: KeyboardEvent): void {
		const keyCode = event.keyCode;

		if (this.activeKeys.has(keyCode)) {
			this.activeKeys.delete(keyCode);
		}

		if (this.tool !== null) {
			Tools[this.tool].onKeyUp(this, event.keyCode);
		}
	}

	protected onMouseDown(event: MouseEvent): void {
		if (event.which > 1) {
			return;
		}

		event.preventDefault();
		event.stopPropagation();

		this.isMouseDown = true;
		this.mousePosition.copy(this.mouseStartPosition = new Position(
			event.clientX,
			event.clientY,
		));

		if (this.tool !== null) {
			Tools[this.tool].onClick(this, this.mouseStartPosition.clone(), false);
		}
	}

	protected onMouseWheel(event: WheelEvent): void {
		event.preventDefault();

		if (this.tool !== null) {
			// send zoom to tool
			Tools[this.tool].onZoom(this, event.deltaY, false);
		}
	}

	protected onMouseMove(event: MouseEvent): void {
		const pos = new Position(event.clientX, event.clientY);
		const movement = this.mousePosition.clone().sub(pos);
		const start = this.mouseStartPosition ? this.mouseStartPosition.clone() : null;

		this.mousePosition.copy(pos);

		if (this.tool !== null) {
			Tools[this.tool].onMove(this, pos, movement, start, false);
		}
	}

	protected onMouseUp(): void {
		if (this.isMouseDown) {
			this.isMouseDown = false;

			if (this.tool !== null) {
				Tools[this.tool].onStop(this, this.mousePosition.clone(), false);
			}
		}
	}

	protected onTouchStart(event: TouchEvent): void {
		this.updateTouches(event);

		event.preventDefault();

		if (this.touchCount === 1) {
			const touch = Array.from(this.activeTouches.values())[0];

			this.touchDistance = 0;
			this.touchStartPosition = new Position(touch.clientX, touch.clientY);
			this.touchPosition = this.touchStartPosition.clone();
		} else if (this.touchCount === 2) {
			this.touchDistance = this.getTouchDistance();
			this.touchStartPosition = this.getTouchCenter();
			this.touchPosition = this.touchStartPosition.clone();
		}

		if (this.touchCount > 0 && this.tool !== null) {
			Tools[this.tool].onClick(this, this.touchStartPosition.clone(), true);
		}
	}

	protected onTouchMove(event: TouchEvent): void {
		if (this.touchCount > 0) {
			const oldPosition = this.touchPosition.clone();

			this.updateTouches(event);

			if (this.touchCount <= 2) {
				const movement = this.touchPosition.clone().sub(oldPosition);

				if (this.tool !== null) {
					Tools[this.tool].onMove(this, this.touchPosition.clone(), movement, this.touchStartPosition.clone(), true);
				}
			}

			if (this.touchCount === 2 && this.tool !== null) {
				Tools[this.tool].onZoom(this, this.getTouchDistance() / this.touchDistance, true);
			}
		}
	}

	protected onTouchEnd(event: TouchEvent): void {
		if (this.touchCount > 0) {
			const lastPosition = this.touchPosition.clone();

			this.updateTouches(event);

			if (this.tool !== null) {
				Tools[this.tool].onStop(this, lastPosition, true);
			}
		}
	}

	private updateTouches(event: TouchEvent): void {
		const removeTouches = event.type === "touchend" || event.type === "touchcancel";
		let addOrRemove = removeTouches;

		if (event.changedTouches) {
			for (const touch of Array.from(event.changedTouches)) {
				if (removeTouches) {
					this.activeTouches.delete(touch.identifier);
				} else {
					if (!this.activeTouches.has(touch.identifier)) {
						addOrRemove = true;
					}

					this.activeTouches.set(touch.identifier, touch);
				}
			}
		}

		this.touchPosition = this.getTouchCenter();

		if (addOrRemove) {
			if (this.touchPosition) {
				this.touchStartPosition = this.touchPosition.clone();
			} else {
				this.touchStartPosition = null;
			}
		}
	}

	private getTouchCenter(): Position {
		if (!this.touchCount) {
			return null;
		}

		const touches = this.activeTouches;

		let x = 0;
		let y = 0;

		touches.forEach(function(touch) {
			x += touch.clientX;
			y += touch.clientY;
		});

		x /= touches.size;
		y /= touches.size;

		return new Position(x, y);
	}

	private getTouchDistance(): number {
		if (!this.touchCount) {
			return null;
		}

		const touches = this.activeTouches;
		const touchArray = Array.from(touches.values());

		const pos1 = new Position(touchArray[0].clientY, touchArray[0].clientY);
		const pos2 = new Position(touchArray[1].clientY, touchArray[1].clientY);

		return pos1.distance(pos2);
	}
}

export namespace Builder {

	export enum Mode {
		VIEW = "view",
		DRAW = "draw",
	}

	export interface IOptions {
		tool?: string;
		unit?: "m" | "ft" | "cm" | "in";
		viewer?: boolean;
		recenter?: boolean;
	}

	export interface IFloor {
		name: string;
		area: number;
		areas: IArea[];
		points: Array<[number, number]>;
	}

	export interface IArea {
		id: number;
		name: string;
		description?: string;
		currency: string;
		price: number;
		fprice: string;
		area: number;
		facilities?: number[];
		points: Array<[number, number]>;
	}

}
