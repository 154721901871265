import { Builder } from "../builder";
import { Position } from "../utils/position";
import { IRenderable } from "../utils/renderable";

export class Rotation implements IRenderable {

	public position: Position;
	public startAngle: number;
	public endAngle: number;
	public distance: number;

	protected middle: Position;

	public constructor(position: Position = new Position(), startAngle: number = 0, endAngle: number = 0, distance: number = 0) {
		this.position = position;
		this.startAngle = startAngle;
		this.endAngle = endAngle;
		this.distance = distance;
	}

	public render(builder: Builder): void {
		if(!this.distance) {
			return;
		}

		const ctx = builder.ctx;

		ctx.save();
		ctx.strokeStyle = "rgba(29, 170, 226, 0.25)";
		ctx.lineWidth = 16 / builder.grid.zoom;
		ctx.setLineDash([2 / builder.grid.zoom]);

		ctx.beginPath();
		ctx.arc(this.position.x, this.position.y, this.distance, this.startAngle, this.endAngle);
		ctx.stroke();

		ctx.restore();
	}

	public order(): number {
		return -50;
	}

}
