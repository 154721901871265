
import { Builder } from "../builder";
import { Position } from "../utils/position";
import { IRenderable } from "../utils/renderable";
import { m2cm, m2ft, m2inch } from "../utils/units";

function drawPath(ctx: CanvasRenderingContext2D, x: number, y: number, w: number, h: number, r: number) {
	ctx.beginPath();
	ctx.moveTo(x + r, y);
	ctx.arcTo(x + w, y, x + w, y + h, r);
	ctx.arcTo(x + w, y + h, x, y + h, r);

	ctx.lineTo(x + w / 2 - 5, y + h);
	ctx.lineTo(x + w / 2, y + h + 10);
	ctx.lineTo(x + w / 2 + 5, y + h);

	ctx.arcTo(x, y + h, x, y, r);
	ctx.arcTo(x, y, x + w, y, r);
	ctx.closePath();
}

export class Measure implements IRenderable {

	public a: Position;
	public b: Position;

	protected middle: Position;

	public constructor(a: Position = null, b: Position = null) {
		this.a = a;
		this.b = b;
		this.middle = new Position();
	}

	public render(builder: Builder): void {
		if(!this.a || !this.b || this.a.distance(this.b) === 0) {
			return;
		}

		const ctx = builder.ctx;

		ctx.textAlign = "center";
		ctx.strokeStyle = "rgba(29, 170, 226, 0.35)";
		ctx.fillStyle = "#00adef";
		ctx.lineJoin = "round";
		ctx.lineWidth = 4 / builder.grid.zoom;
		ctx.setLineDash([10 / builder.grid.zoom]);

		// dotted line
		ctx.beginPath();
		ctx.moveTo(this.a.x, this.a.y);
		ctx.lineTo(this.b.x, this.b.y);
		ctx.stroke();
		ctx.closePath();

		// first dot
		ctx.beginPath();
		ctx.arc(this.a.x, this.a.y, 4 / builder.grid.zoom, 0, 2 * Math.PI);
		ctx.fill();
		ctx.closePath();

		// second dot
		ctx.beginPath();
		ctx.arc(this.b.x, this.b.y, 4 / builder.grid.zoom, 0, 2 * Math.PI);
		ctx.fill();
		ctx.closePath();

		builder.grid.undoTransform();

		ctx.fillStyle = "#000000";
		ctx.textAlign = "center";
		ctx.textBaseline = "middle";
		ctx.font = "bold 16px FiraGO";

		const middle = this.a.middle(this.b);
		const distance = this.a.distance(this.b);

		const textX = (builder.grid.x + middle.x) * builder.grid.zoom;
		const textY = (builder.grid.y + middle.y) * builder.grid.zoom - 30;

		let distanceText = "";
		if(builder.unit === "m") {
			if(distance < 1) {
				distanceText = ((distance * 100) | 0) + " cm";
			} else {
				distanceText = distance.toFixed(2).replace(".00", "") + " m";
			}
		} else if(builder.unit === "ft") {
			let feet = m2ft(distance);
			if(feet >= 3) {
				feet /= 3;
				distanceText = feet.toFixed(2).replace(".00", "") + " yd";
			} else {
				distanceText = feet.toFixed(2).replace(".00", "") + " ft";
			}
		} else if(builder.unit === "cm") {
			let cm = m2cm(distance);
			distanceText = cm.toFixed(2).replace(".00", "") + " cm";
		} else if(builder.unit === "in") {
			let inch = m2inch(distance);
			distanceText = inch.toFixed(2).replace(".00", "") + " in";
		}

		const textSize = ctx.measureText(distanceText);
		const textWidth = textSize.width;

		ctx.fillStyle = "#ffffff";
		//ctx.fillStyle = "#FFFF00";

		ctx.shadowOffsetX = 1;
		ctx.shadowOffsetY = 3;
		ctx.shadowBlur = 5;
		ctx.shadowColor = "rgba(0, 0, 0, 0.1)";

		drawPath(ctx, textX - textWidth / 2 - 8, textY - (16 * 1.2 / 2) - 4, textWidth + 16, (16 * 1.2) + 8, 3);
		ctx.fill();

		ctx.fillStyle = "#000000";
		ctx.fillText(distanceText, textX, textY);
		ctx.restore();
	}

	public order(): number {
		return 100;
	}

}
