import { Builder } from "../builder";
import { Position } from "../utils/position";
import { Tools } from "./index";
import { Move } from "./move";
import { Tool } from "./tool";

export class MoveProxy extends Tool {

	public tool: Tool;

	public constructor(tool: Tool) {
		super(tool.name);
		this.tool = tool;
	}

	public onKeyUp(builder: Builder, keyCode: number): void {
		this.tool.onKeyUp(builder, keyCode);
	}

	public onKeyDown(builder: Builder, keyCode: number): void {
		this.tool.onKeyDown(builder, keyCode);
	}

	public onClick(builder: Builder, pos: Position, isTouch: boolean): void {
		if(builder.activeKeys.has(17) || !!this.tool.forceMove || builder.touchCount === 2) {
			Tools.move.onClick(builder, pos, isTouch);
		} else {
			this.tool.onClick(builder, pos, isTouch);

			if(!!this.tool.forceMove) {
				Tools.move.onClick(builder, pos, isTouch);
			}
		}
	}

	public onZoom(builder: Builder, zoom: number, isTouch: boolean): void {
		Tools.move.onZoom(builder, zoom, isTouch);
		this.tool.onZoom(builder, zoom, isTouch);
	}

	public onMove(builder: Builder, pos: Position, movement: Position, start: Position, isTouch: boolean): void {
		if((Tools.move as Move).moving) {
			Tools.move.onMove(builder, pos, movement, start, isTouch);
		} else {
			this.tool.onMove(builder, pos, movement, start, isTouch);
		}
	}

	public onStop(builder: Builder, pos: Position, isTouch: boolean): void {
		if(builder.activeKeys.has(17) || (Tools.move as Move).moving) {
			Tools.move.onStop(builder, pos, isTouch);

			if(this.tool.forceMove) {
				this.tool.forceMove = false;
			}
		} else {
			this.tool.onStop(builder, pos, isTouch);
		}
	}

	public enable(builder: Builder): void {
		this.tool.enable(builder);
	}

	public disable(builder: Builder): void {
		this.tool.disable(builder);
	}

	public render(builder: Builder, delta: number): void {
		this.tool.render(builder, delta);
	}

}
