export const METER_TO_FEET = 3.28084;
export const METER_TO_INCH = 39.3701;
export const METER_TO_CM = 100;

export const FEET_TO_METER = 1 / METER_TO_FEET;
export const INCH_TO_METER = 1 / METER_TO_INCH;
export const CM_TO_METER = 1 / METER_TO_CM;


export function m2ft(value: number, squared: boolean = false): number {
	if (squared) {
		return value * METER_TO_FEET ** 2;
	} else {
		return value * METER_TO_FEET;
	}
}

export function ft2m(value: number, squared: boolean = false): number {
	if (squared) {
		return value / METER_TO_FEET ** 2;
	} else {
		return value / METER_TO_FEET;
	}
}

export function m2cm(value: number, squared: boolean = false): number {
	if (squared) {
		return value * METER_TO_CM ** 2;
	} else {
		return value * METER_TO_CM;
	}
}

export function m2inch(value: number, squared: boolean = false): number {
	if (squared) {
		return value * METER_TO_INCH ** 2;
	} else {
		return value * METER_TO_INCH;
	}
}

export function applyUnit(m: number, unit: "m" | "ft" | "cm" | "in", squared: boolean = false): number {
	if (unit === "m") {
		return m;
	} else if (unit === "cm") {
		return m2cm(m, squared);
	} else if (unit === "in") {
		return m2inch(m, squared);
	} else {
		return m2ft(m, squared);
	}
}


export function reverseUnit(m: number, unit: "m" | "ft" | "cm" | "in", squared: boolean = false): number {
	if (unit === "m") {
		return m;
	} else if (unit === "cm") {
		return m2cm(m, squared);
	} else if (unit === "in") {
		return m2inch(m, squared);
	} else {
		return m2ft(m, squared);
	}
}
