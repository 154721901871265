import Polygon from "polygon";
import { Builder } from "../builder";
import { Path } from "../objects/path";
import { Position } from "../utils/position";
import { Tool } from "./tool";

export class Move extends Tool {

	public moving: boolean;
	public startZoom: number;
	public ignoreActions: boolean;
	public clickTimeout: number;
	public target: Path;

	public constructor() {
		super("move");
		this.moving = false;
		this.startZoom = null;
		this.ignoreActions = false;
		this.target = null;
	}

	public onZoom(builder: Builder, zoom: number, isTouch: boolean): void {
		if(isTouch) {
			if(!builder.target && builder.touchCount === 2) {
				builder.grid.zoom = this.startZoom * zoom;
			}
		} else {
			builder.grid.zoom += zoom / 10;
		}

		builder.grid.zoom = Math.max(builder.grid.zoom, 2);
	}

	public onClick(builder: Builder, pos: Position, isTouch: boolean): void {
		this.ignoreActions = builder.activeKeys.has(17) || builder.mode === Builder.Mode.VIEW;
		this.moving = true;

		if(isTouch && builder.touchCount === 2) {
			this.startZoom = builder.grid.zoom;
		}

		builder.setCursor("move");
	}

	public onStop(builder: Builder, pos: Position, isTouch: boolean): void {
		if(!isTouch || (isTouch && builder.touchCount !== 1)) {
			this.moving = false;
		}

		builder.setCursor(null);
	}

	public onMove(builder: Builder, pos: Position, movement: Position, start: Position, isTouch: boolean): void {
		if(this.moving) {
			builder.grid.x += movement.x / builder.grid.zoom;
			builder.grid.y += movement.y / builder.grid.zoom;
			builder.emit("view");
		}
	}

}
