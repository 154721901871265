import Polygon from "polygon";
import { Builder } from "../builder";
import { Path } from "../objects/path";
import { Position } from "../utils/position";
import { Tool } from "./tool";

export class Select extends Tool {

	public isMoving: boolean;
	public isActive: boolean;

	public clickTimeout: number;
	public target: Path;

	public constructor() {
		super("select");
		this.isMoving = false;
		this.isActive = false;
		this.clickTimeout = null;
		this.target = null;
	}

	public onClick(builder: Builder, pos: Position, isTouch: boolean): void {
		const target = this.target;

		this.isMoving = false;
		this.isActive = true;
		this.target = null;

		builder.emit("blur");
		builder.setTarget(target);
		builder.setCursor(null);

		if(!!target) {
			builder.emit("focus", target);

			if(builder.mode !== Builder.Mode.VIEW) {
				if(this.clickTimeout) {
					clearTimeout(this.clickTimeout);
				}

				this.clickTimeout = window.setTimeout(() => {
					this.clickTimeout = null;

					if(!this.isActive) {
						builder.emit("click", target);
					} else {
						this.isMoving = true;
					}
				}, 150);
			} else {
				builder.emit("info", target);
			}
		} else {
			if(builder.mode === Builder.Mode.VIEW) {
				this.forceMove = true;
			} else {
				builder.emit("blur");
			}
		}
	}

	public onMove(builder: Builder, pos: Position, movement: Position, start: Position, isTouch: boolean): void {
		if(builder.target && this.isMoving) {
			if(this.clickTimeout) {
				clearTimeout(this.clickTimeout);
				this.clickTimeout = null;
			}

			const target = builder.target;

			if(target) {
				const offset = target.offset;

				offset.copy(pos).sub(start);
				offset.x /= builder.grid.zoom;
				offset.y /= builder.grid.zoom;

				builder.grid.applyGrid(offset);

				const pointPos = target.topLeft;
				const fixedPointPos = pointPos.clone();

				builder.grid.applyGrid(fixedPointPos);

				offset.x += fixedPointPos.x - pointPos.x;
				offset.y += fixedPointPos.y - pointPos.y;

				builder.emit("move", target);
			}
		} else {
			this.updateHighlight(builder, pos);
		}
	}

	public onStop(builder: Builder, pos: Position, isTouch: boolean): void {
		if(!isTouch || (isTouch && builder.touchCount !== 1)) {
			const target = builder.target;

			if(target) {
				target.applyOffset();
				builder.emit("moved", target);
			}

			if(this.isMoving) {
				builder.setTarget(null);
				builder.emit("blur");
			}

			this.isMoving = false;
			this.isActive = false;
			this.updateHighlight(builder, pos);
		}

		if(this.clickTimeout) {
			clearTimeout(this.clickTimeout);
			this.clickTimeout = null;

			builder.emit("click", builder.target);
		}
	}

	public disable(builder: Builder): void {
		if(this.target) {
			this.target.highlight = false;
			this.target = null;
		}

		if(builder.target && builder.tool === "delete") {
			const path = builder.target;
			builder.renderables.delete(path);
			builder.emit("delete", path);
		}

		builder.setTarget(null);
		builder.emit("blur");

		this.isActive = false;
		this.isMoving = false;
	}

	protected updateHighlight(builder: Builder, pos: Position): Path {
		if(this.target) {
			if(this.target !== builder.target) {
				this.target.highlight = false;
			}
			this.target = null;
		}

		if(this.isMoving || (builder.target && builder.mode === Builder.Mode.VIEW)) {
			return null;
		}

		const paths = builder.findPaths(builder.applyCanvasPosition(pos.clone()));

		if(paths.length) {
			(this.target = paths[0]).highlight = true;
			if(this.target !== builder.target) {
				builder.setCursor("pointer");
			}
		} else {
			builder.setCursor(null);
		}

		return this.target;
	}

}
