import { Builder } from "./builder";
import { Menu } from "./menu";
import { Path } from "./objects/path";
import { Popup } from "./popup";
import * as Currency from "./utils/currency";
import { ICurrency } from "./utils/currency";
import { escapeHTML } from "./utils/html";
import { applyUnit, reverseUnit } from "./utils/units";

export interface IFacility {
	id: number;
	media_id: number;
	icon: string;
	name: string;
	description: string;
	created_at: string;
	updated_at: string;
}

export class Controller {

	public builder: Builder;
	public facilities: IFacility[];
	
	//public currencies: ICurrency[];

	public floorPopup: Popup;
	public areaPopup: Popup;
	public donePopup: Popup;
	public infoPopup: Popup;
	public mode: Controller.Mode;
	public menu: Menu;

	public constructor(builder: Builder) {
		this.builder = builder;
		
		this.facilities = [];
		//this.currencies = [];

		this.setMode(Controller.Mode.FLOOR);

		this.floorPopup = new Popup(builder, {
			title: "Floor Plan",
			content: [
				{ type: "text", text: "Enter a name for the area you have highlighed:"},
				{ type: "input", name: "name", placeholder: "e.g. floor 1"},
				{ type: "text", html: "What is your total area (in {{unit}}&sup2;)?"},
				{ type: "input", name: "area", placeholder: "e.g. 50{{unit}}&sup2;"},
			],
			actions: [
				{ type: "close", name: "Cancel"},
				{ type: "event", name: "Submit", event: "submit"},
			],
		});

		this.areaPopup = new Popup(builder, {
			title: "Area",
			content: [
				{ type: "text", text: "Enter a name for the area you have highlighed:"},
				{ type: "input", name: "name", placeholder: "e.g. section 1"},
				{ type: "text", text: "Enter a description:"},
				{ type: "textarea", name: "description", rows: 5},
				{ type: "text", text: "What is the price (£)?"},
				{ 
					type: "price", 
					name: "price",
					//currencies: this.currencies,
				},
				{ type: "text", html: "What is your total area (in {{unit}}&sup2;)?"},
				{ type: "input", name: "area", placeholder: "e.g. 50{{unit}}&sup2;"},
				{ type: "text", text: "Does this area have any specific facilities?"},
				{
					type: "checkbox",
					name: "facilities",
					columns: 2,
					options: () => {
						return this.facilities.map(facility => {
							return {
								label: facility.name,
								value: facility.id,
							};
						});
					},
				},
			],
			actions: [
				{ type: "close", name: "Cancel"},
				{ type: "event", name: "Submit", event: "submit"},
			],
		});

		this.donePopup = new Popup(builder, {
			title: "Thank You!",
			ignoreBlocker: false,
			content: [{ type: "text", text: "Your floor plan has been set up."}],
			actions: [{ type: "close", name: "Close"}],
		});

		this.infoPopup = new Popup(builder, {
			ignoreBlocker: false,
			actions: [{ type: "close", name: "Close"}],
		});
	}

	public openInfoPopup(path: Path): void {
		
		//let price = path.price.toLocaleString();
		let price = path.fprice;

		/*
		const currency = Currency.get(path.currency);
		const symbolBefore = currency.position === "before";
		
		const event = new CustomEvent("addToCart", { detail: path.id});
		if (symbolBefore) {
			price = currency.html + price;
		} else {
			price += currency.html;
		}
		*/
		
		this.infoPopup.title = path.name;
		this.infoPopup.content = [
			{
				type: "text",
				html: `
					<div class="rb-facility">
						<div class="rb-facility__details">
							<span class="rb-facility__title">${price} / Total Area: ${applyUnit(path.area, this.builder.unit, true) | 0}${this.builder.unit}&sup2;</span>
							${path.description ? `
								<p class="rb-facility__desc">
									${escapeHTML(path.description)}
								</p>
							` : ""}
						</div>
					</div>
					${path.getFacilities(this.facilities).map(facility => `
						<div class="rb-facility">
							<div class="rb-facility__details">
								<span class="rb-facility__title">${escapeHTML(facility.name)}</span>
								<p class="rb-facility__desc">${escapeHTML(facility.description)}</p>
							</div>
							<i class="${escapeHTML(facility.icon)}"></i>
						</div>
					`).join("")}
					<button  onclick="document.dispatchEvent(new CustomEvent('addToCart', { detail: ${path.id}}));">Add To Cart</button>
				`,
			},
		];
		document.addEventListener("addToCart", () => {
			this.infoPopup.close();
		});
		this.infoPopup.renderEl();
		this.infoPopup.open();

		this.infoPopup.once("close", () => {
			this.builder.setTarget(null);
			this.builder.emit("blur", path);
		});
	}

	public setMode(mode: Controller.Mode): void {
		this.builder.el.attr("data-stage", this.mode = mode);
	}

	public closeMenu(): void {
		if (this.menu) {
			this.menu.remove();
			this.menu = null;
		}
	}

	public async init(mode: Builder.Mode = Builder.Mode.DRAW): Promise<void> {
		
		await this.loadFacilities();
		//await this.loadCurrencies();

		this.builder.setMode(mode);

		if (mode === Builder.Mode.VIEW) {
			this.mode = Controller.Mode.DONE;
		}

		this.builder.on("blur", this.closeMenu.bind(this));

		this.builder.on("click", (path: Path) => {
			if (this.builder.mode === Builder.Mode.DRAW) {
				const pos = this.builder.revertCanvasPosition(path.center.clone(), false);
				this.menu = new Menu(this.builder, {
					hidden: false,
					x: pos.x,
					y: pos.y,
					items: [
						{
							name: "edit",
							icon: "fal fa-edit",
							text: "Edit",
							callback: () => {
								switch (this.mode) {
									case Controller.Mode.FLOOR:
										this.applyFloorDetails(path);
										break;
									case Controller.Mode.AREA:
										this.applyAreaDetails(path);
										break;
								}
							},
						},
						{
							name: "delete",
							icon: "fal fa-trash",
							text: "Delete",
							callback: () => {
								this.builder.renderables.delete(this.builder.target);
								this.closeMenu();
							},
						},
						{
							name: "close",
							icon: "fal fa-times",
							text: "Close",
							callback: () => {
								this.builder.setTarget(null);
								this.closeMenu();
							},
						},
					],
				});
			}
		});

		this.builder.on("add", async (path: Path) => {
			if (this.mode === Controller.Mode.FLOOR) {
				path.type = "floor";

				this.applyFloorDetails(path)
					.then(() => this.updateControls())
					.catch(() => {
						this.builder.renderables.delete(path);
						this.builder.setTarget(null);
						this.updateControls();
					});
			} else if (this.mode === Controller.Mode.AREA) {
				path.type = "area";

				this.applyAreaDetails(path)
					.then(() => this.updateControls())
					.catch(() => {
						this.builder.renderables.delete(path);
						this.builder.setTarget(null);
						this.updateControls();
					});
			}
		});

		this.builder.on("delete", this.updateControls.bind(this));
		this.builder.on("moved", this.updateControls.bind(this));

		this.builder.on("next", () => {
			if (this.mode === Controller.Mode.FLOOR) {
				this.setMode(Controller.Mode.AREA);
				this.builder.getPaths().forEach(path => {
					if (path.type === "floor") {
						path.locked = true;
					} else if (path.type === "area") {
						path.hidden = false;
					}
				});
			} else if (this.mode === Controller.Mode.AREA) {
				this.setMode(Controller.Mode.DONE);
				this.builder.setMode(Builder.Mode.VIEW);
				this.builder.emit("done", this.builder.export());
				this.donePopup.open();
			}
			this.updateControls();
		});

		this.builder.on("prev", () => {
			if (this.mode === Controller.Mode.DONE) {
				this.setMode(Controller.Mode.AREA);
				this.donePopup.close();
				this.builder.emit("done", null);
				this.builder.setMode(Builder.Mode.DRAW);
			} else if (this.mode === Controller.Mode.AREA) {
				this.setMode(Controller.Mode.FLOOR);
				this.builder.getPaths().forEach(path => {
					if (path.type === "area") {
						path.hidden = true;
					} else if (path.type === "floor") {
						path.locked = false;
					}
				});
			}
			this.updateControls();
		});

		this.builder.on("info", (path: Path) => {
			this.openInfoPopup(path);
		});

		this.builder.on("move", this.checkPath.bind(this));
		this.builder.on("moved", this.checkPaths.bind(this));
		this.builder.on("add", this.checkPaths.bind(this));
		this.builder.on("delete", this.checkPaths.bind(this));
		this.builder.on("rotated", this.checkPaths.bind(this));
	}

	public updateControls(): void {
		const paths = this.builder.getPaths();

		this.checkPaths();

		if (this.mode === Controller.Mode.FLOOR) {
			const floors = paths.filter(filteredPath => filteredPath.type === "floor");

			if (!floors.length) {
				this.builder.emit("next:disable");
			} else {
				for (const floor of floors) {
					if (!!floor.error) {
						this.builder.emit("next:disable");
						return;
					}
				}

				this.builder.emit("next:enable");
			}

			this.builder.emit("prev:disable");
		} else if (this.mode === Controller.Mode.AREA) {
			const areas = paths.filter(filteredPath => filteredPath.type === "area");

			if (!areas.length) {
				this.builder.emit("next:disable");
			} else {
				for (const area of areas) {
					if (!!area.error) {
						this.builder.emit("next:disable");
						return;
					}
				}

				this.builder.emit("next:enable");
			}

			this.builder.emit("prev:enable");
		} else if (this.mode === Controller.Mode.DONE) {
			this.builder.emit("next:disable");
			this.builder.emit("prev:enable");
		}
	}

	public checkPaths(): void {
		const paths = this.builder.getPaths();

		for (const path of paths) {
			this.checkPath(path, paths);
		}
	}

	public checkPath(path: Path, paths?: Path[]): void {
		if (!paths) {
			paths = this.builder.getPaths();
		}

		if (this.mode === Controller.Mode.FLOOR) {
			const floors = paths.filter(filteredPath => filteredPath.type === "floor");
			path.error = path.overlaps(floors, true);
		} else {
			const floors = paths.filter(filteredPath => filteredPath.type === "floor");
			const areas = paths.filter(filteredPath => filteredPath.type === "area");

			let floor = null;

			for (const arrFloor of floors) {
				if (arrFloor !== path && arrFloor.contains(path, true)) {
					floor = arrFloor;
					break;
				}
			}

			path.error = !floor || path.overlaps(areas, true);
		}
	}

	public applyFloorDetails(path: Path): Promise<void> {
		return new Promise((resolve: () => void, reject: () => void) => {
			const unit = this.builder.unit;
			let done = false;

			this.floorPopup.on("submit", data => {
				if (!done) {
					const name = data.name;
					const area = data.area - 0;

					if (!name.length) {
						alert("Please enter an area name.");
					} else if (isNaN(area) || area === 0) {
						alert("Please enter a valid area.");
					} else {
						done = true;
						this.floorPopup.close();

						path.name = name;
						path.area = reverseUnit(area, unit, true);

						resolve();
					}
				}
			});

			this.floorPopup.on("close", () => {
				this.floorPopup.removeAllListeners();

				if (!done) {
					reject();
				}
			});

			this.floorPopup.setValues({
				name: path.name,
				area: Math.round(applyUnit(path.area, unit, true)),
			});

			this.floorPopup.open();
		});
	}

	public applyAreaDetails(path: Path): Promise<void> {
		return new Promise((resolve: () => void, reject: () => void) => {
			const unit = this.builder.unit;
			let done = false;

			this.areaPopup.on("submit", data => {
				if (!done) {
					const name = data.name;
					const description = data.description;
					const price = data.price;
					const area = data.area - 0;
					const id = data.id;
					const facilities = data.facilities;

					if (!name.length) {
						alert("Please enter an area name.");
					} else if (!price || isNaN(price.amount) || price.amount <= 0 || !price.currency) {
						alert("Please enter a valid price.");
					} else if (isNaN(area) || area === 0) {
						alert("Please enter a valid area.");
					} else {
						done = true;
						this.areaPopup.close();

						path.name = name;
						path.description = description;
						path.price = price.amount;
						path.id = id;
						path.currency = price.currency;
						path.area = reverseUnit(area, unit, true);
						path.facilities = facilities;

						resolve();
					}
				}
			});

			this.areaPopup.on("close", () => {
				this.areaPopup.removeAllListeners();

				if (!done) {
					reject();
				}
			});

			this.areaPopup.setValues({
				name: path.name,
				description: path.description,
				id: path.id,
				price: {
					currency: path.currency,
					amount: path.price,
				},
				area: applyUnit(path.area, unit, true),
				facilities: path.facilities ? Array.from(path.facilities.values()) : [],
			});

			this.areaPopup.open();
		});
	}

	public async loadFacilities(): Promise<boolean> {
		return fetch("/api/facilities")
			.then(res => res.json())
			.then(json => {
				for (const facility of json) {
					this.facilities.push(facility);
				}
				return true;
			})
			.catch(() => false);
	}

	/*
	public async loadCurrencies(): Promise<boolean> {

		return fetch('/api/listerCurrencies')
			.then(res => res.json())
			.then((result) => {
				for (const facility of result.value) {
					this.currencies.push(facility);
				}
				return true;
			}, (error) => {
				console.log('error', error)
				return false;
			})


		return fetch("/api/listerCurrencies")
			.then(res => res.json())
			.then(json => {
				for (const facility of json) {
					this.currencies.push(facility);
				}
				return true;
			})
			.catch(() => false);
	}
	*/

	public renderEl(): void {
		this.floorPopup.renderEl();
		this.areaPopup.renderEl();
		this.donePopup.renderEl();
		//this.infoPopup.close();
	}

	public get isBusy(): boolean {
		return this.floorPopup.isOpen || this.areaPopup.isOpen || this.donePopup.isOpen;
	}

}

export namespace Controller {

	export enum Mode {
		FLOOR = "floor",
		AREA = "area",
		DONE = "done",
	}

}
