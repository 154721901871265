import { Builder } from "../builder";
import { Path } from "../objects/path";
import { Position } from "../utils/position";
import { Tools } from "./index";
import { Move } from "./move";
import { Tool } from "./tool";

export class Delete extends Tool {

	public target: Path;

	public constructor() {
		super("delete");
		this.target = null;
	}

	public onClick(builder: Builder, pos: Position, isTouch: boolean): void {
		const target = this.getTarget(builder, pos);
		if(target && target.locked) {
			return;
		}

		builder.setTarget(target);
	}

	public onStop(builder: Builder, pos: Position): void {
		if(builder.target) {
			const path = this.getTarget(builder, pos);
			if(path && path === builder.target) {
				builder.renderables.delete(path);
				builder.emit("delete", path);
			}

			builder.setTarget(null);
		}
	}

	protected getTarget(builder: Builder, pos: Position): Path {
		const paths = builder.findPaths(builder.applyCanvasPosition(pos));
		if(paths.length) {
			return paths[0];
		} else {
			return null;
		}
	}

}
