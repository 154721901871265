import { Builder } from "./builder";

// import styles
import "./builder.scss";

const MAP = new Map<string, Builder>();

export async function create(el: string, options?: Builder.IOptions): Promise<Builder> {
	if(MAP.has(el)) {
		console.warn("Re-using canvas for builder will result in ignored parameters.");
		return MAP.get(el);
	} else {
		try {

			const builder = await Builder.create(el, options);

			//const builder = new Builder(el, options);
			
			MAP.set(el, builder);
			return builder;
		} catch(err) {
			console.warn("Error creating builder:", err);
			return null;
		}
	}
}
