import { Builder } from "../builder";
import { Measure } from "../objects/measure";
import { Path } from "../objects/path";
import { Position } from "../utils/position";
import { Tool } from "./tool";

const KEY_EXIT = 27;
const KEY_UNDO = 8;
const KEY_CTRL = 17;
const KEY_CTRL_UNDO = 90;
const KEY_CTRL_REDO = 89;

export class Draw extends Tool {

	public path: Path;
	public measure: Measure;
	public history: Position[];

	public constructor() {
		super("draw");
		this.path = null;
		this.measure = null;
		this.history = null;
	}

	public onKeyUp(builder: Builder, keyCode: number): void {
		if(!this.path) {
			return;
		}

		const isCtrl = builder.activeKeys.has(KEY_CTRL);

		if(keyCode === KEY_EXIT) {
			this.exit();
		} else if(keyCode === KEY_UNDO || (isCtrl && keyCode === KEY_CTRL_UNDO)) {
			this.undo();
		} else if(isCtrl && keyCode === KEY_CTRL_REDO) {
			this.redo();
		}

		this.updateHistory(builder);
	}

	public exit(): void {
		this.path = null;
		this.measure = null;
	}

	public undo(): void {
		if(!this.path || !this.history) {
			return;
		}

		const path = this.path;

		if(path.points.length <= 1) {
			this.path = null;
			this.measure = null;
		} else {
			const pos = this.path.points[this.path.points.length - 2];

			this.history.unshift(this.path.points.pop());
			this.measure.a.copy(pos);
		}
	}

	public redo(): void {
		if(!this.path || !this.history || !this.history.length) {
			return;
		}

		const pos = this.history.shift();

		this.path.points.push(pos);
		this.measure.a.copy(pos);
	}

	public onClick(builder: Builder, pos: Position, isTouch: boolean): void {
		builder.applyCanvasPosition(pos);
		builder.grid.applyGrid(pos);

		if(!this.path) {
			this.path = new Path([pos], true, true);
			this.measure = new Measure(
				pos.clone(),
				pos.clone(),
			);

			this.history = [];
			this.updateHistory(builder);
		}
	}

	public onZoom(builder: Builder, zoom: number, isTouch: boolean): void {
		if(isTouch) {
			this.path = null;
		}
	}

	public onMove(builder: Builder, pos: Position, movement: Position, start: Position, isTouch: boolean): void {
		if(this.measure) {
			builder.applyCanvasPosition(this.measure.b.copy(pos));
			builder.grid.applyGrid(this.measure.b);
		}
	}

	public onStop(builder: Builder, pos: Position, isTouch: boolean): void {
		builder.applyCanvasPosition(pos);
		builder.grid.applyGrid(pos);

		if(this.path) {
			if(isTouch && this.path.points.length === 1 && !this.measure.a.distance(pos)) {
				this.path = null;
				this.measure = null;
				this.history = null;
			} else {
				const firstPoint = this.path.points[0];

				if(this.path.points.length > 1 && firstPoint.distance(pos) * builder.grid.zoom < 10) {
					this.path.hollow = false;
					this.path.highlight = null;
					this.path.update();

					if(this.path.area > 0) {
						builder.renderables.add(this.path);
						builder.emit("add", this.path);
					} else {
						alert("Invalid shape!");
					}

					this.path = null;
					this.measure = null;
					this.history = null;
				} else {
					const lastPoint = this.path.points[this.path.points.length - 1];

					if(!lastPoint.equals(pos)) {
						this.path.points.push(pos);
						this.measure.a.copy(pos);
						this.measure.b.copy(pos);
						this.history = [];
					}
				}
			}
		}

		if(isTouch && this.measure) {
			if(this.measure.a.distance(this.measure.b) > 0) {
				this.path.points.push(this.measure.b.clone());
				this.measure.a.copy(this.measure.b);
			}
		}

		this.updateHistory(builder);
	}

	public enable(builder: Builder): void {
		builder.history.on("redo", this.redo.bind(this));
		builder.history.on("undo", this.undo.bind(this));
		builder.history.on("exit", this.exit.bind(this));

		builder.history.on("redo", () => this.updateHistory(builder));
		builder.history.on("undo", () => this.updateHistory(builder));
		builder.history.on("exit", () => this.updateHistory(builder));
	}

	public disable(builder: Builder): void {
		if(this.path) {
			this.path = null;
			this.measure = null;
			this.history = null;

			if(builder.tool === "delete") {
				builder.setTool(this.name);
			}
		}

		builder.history.hidden = true;
		builder.history.removeAllListeners();
	}

	public render(builder: Builder): void {
		if(this.path) {
			builder.grid.applyTransform();

			this.path.render(builder);
			this.measure.render(builder);

			builder.ctx.restore();
		}
	}

	protected updateHistory(builder: Builder): void {
		if(this.path && this.history) {
			builder.history.onlyExit = false;
			builder.history.hidden = false;

			if(this.history.length) {
				builder.history.enable("redo");
			} else {
				builder.history.disable("redo");
			}
		} else {
			builder.history.hidden = true;
		}
	}

}
